/*
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-05-22 09:11:33
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-12-10 09:44:26
 * @FilePath: \qcwp-vue\packages\web\src\composables\useAddAccess.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { isClient } from '@qcwp/utils'
import { siteAccess } from '~~/src/server/modules/base'
/**
 * 访问统计
 */
export function useAddAccess() {
  if (!isClient)
    return
  try {
    siteAccess()
  }
  catch (error) {
    console.error(error)
  }
}
